import {
  formatMoney,
  KonakartService,
  useTotals,
  useCartContainer,
  CouponTypeValue,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ,
  useManagePackage,
} from '@sky-tv-group/shared';

import { ChevronDown, ChevronUp, RunningTotalInner } from '@sky-tv-group/components';

import React, { useRef, useState } from 'react';
import { ButtonArea } from './ButtonArea';
import { myAccountSWR } from '../../services';
import './billing.css';
import { useEffect } from 'react';
interface Props {
  show?: boolean;
  konakartService: KonakartService;
}
const RunningTotal = ({ show = true, konakartService }: Props) => {
  const [active, setActiveState] = useState('block');
  const [buttonIcon, setButtonIconState] = useState('-');
  const [mobileButtonIcon, setMobileButtonIconState] = useState(false);

  let stickyRef = useRef<HTMLDivElement>(null) as any;

  function handleStickyBarScroll() {
    let footerElement = document.querySelector('#footerTermsAndCond') as any;
    if (stickyRef.current) {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        footerElement.style.marginBottom = stickyRef.current.offsetHeight + 'px';
      } else {
        footerElement.style.marginBottom = '0px';
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleStickyBarScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyBarScroll);
    };
  }, []);

  function toggle() {
    setActiveState(active === 'hidden' ? 'block' : 'hidden');
    setButtonIconState(buttonIcon === '+' ? '-' : '+');
    setMobileButtonIconState(mobileButtonIcon ? false : true);
  }
  const totals = useTotals(myAccountSWR);

  const { coupons } = useCartContainer(konakartService, CouponTypeValue.Upgrade);
  const hasBBcoupon = coupons.find(
    coupon =>
      coupon.couponCode === NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON ||
      coupon.couponCode === NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ
  );
  const showOfferEndData = !hasBBcoupon;
  const { allActiveCouponsEndDate, couponDuration } = useManagePackage(myAccountSWR);

  if (!show) return null;

  return (
    <div className="flex flex-col md:flex-col bg-white right-0 border-l border-b lg:h-full" data-testid="running-total">
      <div id="running-total-content" className={'lg:sticky'} style={{ top: '60px' }}>
        <div>
        {/* h-125px  this call will be added after sport price increase done*/}
          <div className=" hidden p-4 padding_box gap-2 lg:flex flex-col items-start">
            {/* with offer */}
            <div className="flex flex-col items-start p-0 h-34px gap-2">
              <div className="flex flex-row items-center justify-end p-0 h-22px">
                <div className="flex flex-row items-start padding_left_10 w-148px h-22px">
                  <span className="sky-h5-black items-center" style={{ color: '#0057ff' }}>
                    Monthly charge
                  </span>
                </div>
                <div className="flex flex-row items-end w-102px h-22px">
                  <span className="sky-h7-reg items-center text-center" style={{ color: '#0057ff' }}>
                    {formatMoney(totals.monthlyPriceWithOffer)}/mth
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-end items-center p-0 h-3">
                {/* <span className="sky-sub" style={{ marginTop: '-5%' }}>
                  With offer applied
                </span> */}
                <span className="sky-sub">
                With offer applied. After offer ends, standard pricing will apply.
                </span>
              </div>
            </div>
            {/* without offer */}
            {/* will be uncomment after sport price implement done from May1st 2025 */}
            {/* <div className="flex flex-col items-start p-0 h-34px gap-2 mt-2">
              <div className="flex flex-row items-center justify-end p-0 h-22px">
                <div className="flex flex-row items-start padding_left_10 w-148px h-22px">
                  <span className="sky-h5-black items-center" style={{ color: '#0057ff' }}>
                    Monthly charge
                  </span>
                </div>
                <div className="flex flex-row items-end w-102px h-22px">
                  <span className="sky-h7-reg items-center text-center" style={{ color: '#0057ff' }}>
                    {formatMoney(totals.monthlyPriceWithoutOffer)}/mth
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-end items-center p-0 h-3">
                <span className="sky-sub">
                  {showOfferEndData ? couponDuration &&`After offer expires ${couponDuration} months from your Sky activation date` : `After offer period`}
                </span>
              </div>
            </div> */}
          </div>
          <div className="hidden lg:block sky-bg-gray-light">
            <ButtonArea />
          </div>
        </div>

        <h4
          onClick={toggle}
          className="sky-h5 text-left py-4 px-10 flex lg:hidden items-center justify-between font-bold"
          style={{ borderTop: '3px solid #ccc', borderBottom: '1px solid #ccc', color: '#0057FF' }}>
          <span>{mobileButtonIcon ? 'Show Summary' : 'Hide Summary'}</span>
          <span style={{ width: '15px', height: '15px', display: 'inline-block', color: '#0057FF' }}>
            {mobileButtonIcon ? (
              <>
                <ChevronUp />
              </>
            ) : (
              <>
                <ChevronDown />
              </>
            )}
          </span>
        </h4>
        <div className={active + ' lg:block md:package-list pt-6'}>
          <div className="">
            <RunningTotalInner show={show} konakartService={konakartService} myAccountSWR={myAccountSWR} />
          </div>
        </div>
        <div className="pr-3 mx-8 ml-10 block lg:hidden">
          <p className="sky-h4-black my-5 flex items-start justify-between" style={{ color: '#0057FF' }}>
            <span>Monthly Plan</span>
            <span>{formatMoney(totals.monthlyPriceWithOffer)}</span>
          </p>
        </div>
        <div
          className="block
             fixed
           min-w-full bottom-0 lg:hidden z-50 md:static left-0"
          ref={stickyRef}>
          <ButtonArea />
        </div>
      </div>
    </div>
  );
};

export { RunningTotal };
