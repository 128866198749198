import { Box, productSkuIds, T_Product, useboxesStore } from '@sky-tv-group/shared';
import React, { FC, useState } from 'react';
import { Modal } from '../modal';
import { SkyBoxDescription } from '../SkyBoxDescription';
import { closex, RedioButton, RedioButtonTick, Success } from '../icon';

type RadioBoxCard = {
  box: T_Product;
  className: String;
  handleRadioClick: (box: T_Product) => Promise<void>;
};

const RadioBoxCard: FC<RadioBoxCard> = ({ box, handleRadioClick, className }) => {
  const boxes = useboxesStore(s => s.boxes);
  const isBoxSelected = boxes.some(b => box.sku === b.serviceCode && b.primary);
  const [showModal, setShowModal] = useState(false);
  function modalClose(e: any) {
    document.getElementById('overlay')?.removeEventListener('click', modalClose, true);
    e.stopPropagation();
    setShowModal(false);
    document.getElementById('root')!.style.opacity = '1';
  }
  return (
    <div
      className={` border-2 rounded-lg flex-1 cursor-pointer ${className}`}
      onClick={() => {
        handleRadioClick(box);
      }}>
      <div
        className=" flex items-center gap-4 p-4 rounded-t-lg "
        style={{ background: 'rgba(251, 251, 251, 1)', height: '30%' }}>
        <div className={`w-15 h-15`}>
        {isBoxSelected ? <RedioButtonTick/> : <RedioButton/>}
        </div>
        <p className="font-bold sky-h4-black text-2xl">{box.name}</p>
      </div>
      <div className=" flex " style={{ height: '70%' }}>
        <div className=" w-1/2 flex-1 ml-6 mt-4">
          <img
            src={
              box.sku === productSkuIds.skyPod.primary
                ? 'https://static.sky.co.nz/sky/box/sky_pod_with_remote.png'
                : box.custom1
            }
            alt="box.img"
            className="object-contain w-full"
            style={{ maxHeight: '100%' }}
            loading="lazy"
          />
        </div>
        <div className=" w-12 flex-1 flex flex-col items-center mt-8">
          <div className="flex-1 flex items-end">
            {' '}
            <p className=" sky-text-red line-through">{box.sku === productSkuIds.arrowBox.primary ? '$200' : '$100'}</p>
          </div>
          <div className="flex">
            <div className="text-xl font-black">
              <span className="text-xs relative ml-1" style={{ top: '-8px' }}>
                $
              </span>
              0.00
            </div>
            <div className="text-xs self-end">/one-off charge</div>
          </div>
          <p
            className="morethings flex-1  underline text-blue-pure text-base pl-4 py-4 cursor-pointer"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              document.getElementById('root')!.style.opacity = '0.5';
              document.getElementById('overlay')?.addEventListener('click', modalClose, true);
              setShowModal(true);
            }}>
            More things to know
          </p>
        </div>
        {showModal && (
          <Modal isOpen={showModal}>
            {' '}
            <div
              className="modalOverlay w-full h-full rounded-none"
              id="overlay"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
              onClick={modalClose}>
              <div
                className="modalOverlay px-10 md:px-20 z-40 flex-col pb-20"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                <div className="flex justify-between items-center w-full mt-4 lg:mb-4">
                  <div className="text-2xl">
                    <span className="font-black">Things to Know</span> about the{' '}
                    {box.sku === productSkuIds.arrowBox.primary ? 'new Sky Box' : 'Sky Pod'} :
                  </div>
                  <div
                    className="w-14 h-14 relative leftalign"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowModal(false);
                      document.getElementById('root')!.style.opacity = '1';
                      document.getElementById('overlay')!.removeEventListener('click', modalClose, true);
                    }}>
                    {closex}
                  </div>
                </div>
                <SkyBoxDescription
                  isSkyPodSelected={box.sku === productSkuIds.skyPod.primary}
                  isArrowSelected={box.sku === productSkuIds.arrowBox.primary}
                  source={'WEB-ICE'}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default RadioBoxCard;
